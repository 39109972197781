import clsx from "clsx";
import { UserRole } from "../../config";
import React, { useCallback } from "react";
import { SIDEBAR_WIDTH_COLLAPSED, SIDEBAR_WIDTH_FULL } from "./sidebar.helper";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { NavigationLink } from "./navlink";
import { UserProfile } from "./user-profile";
import { Link } from "react-router-dom";

export type SidebarNavigationItems = {
  to: string;
  label: string;
  isActive: boolean;
  fullIcon?: JSX.Element;
  activeIcon?: JSX.Element;
  collapsedIcon?: JSX.Element;
  navigationExclusiveToRole: UserRole[];
};

export type UserProfileProps<T> = {
  userProfile: T;
  isActive: boolean;
  collapsed: boolean;
  handleLogout: () => void;
  isActiveClassName: string;
  profileClassName?: string;
  setIsCollapsed: (collapsed: boolean) => void;
};

export type BaseSidebarProps = {
  collapsed: boolean;
  setIsCollapsed: (collapsed: boolean) => void;
  navigationItems: SidebarNavigationItems[];
  toProfileRoute: string;
  pathname: string;
  infoCard?: JSX.Element;
  fullWidth?: string;
  collapsedWidth?: string;
  isActiveClassName?: string;
  heroFullIcon?: JSX.Element;
  containerClassName?: string;
  heroCollapsedIcon?: JSX.Element;
  collapseButtonClassName?: string;
  elementContainerClassName?: string;
};

export type ProfileRequiredProps<T> = {
  profileSection?: never;
  userProfile: T;
  handleLogout: () => void;
  profileClassName?: string;
  profileRouteActiveString?: string;
};

export type ProfileOptionalProps<T> = {
  profileSection: JSX.Element;
  userProfile?: T;
  handleLogout?: () => void;
  profileClassName?: string;
  profileRouteActiveString?: string;
};

export type CustomSidebarProps<
  T = {
    id?: string;
    role?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  },
> = BaseSidebarProps & (ProfileRequiredProps<T> | ProfileOptionalProps<T>);

const CustomSidebar: React.FC<CustomSidebarProps> = ({
  pathname,
  collapsed,
  heroFullIcon,
  setIsCollapsed,
  navigationItems,
  heroCollapsedIcon,
  isActiveClassName,
  containerClassName,
  collapseButtonClassName,
  elementContainerClassName,
  infoCard,
  fullWidth = SIDEBAR_WIDTH_FULL,
  collapsedWidth = SIDEBAR_WIDTH_COLLAPSED,

  userProfile,
  handleLogout,
  profileSection,
  profileClassName,
  profileRouteActiveString = "profile",
}) => {
  const toggleCollapse = useCallback(
    () => setIsCollapsed(!collapsed),
    [collapsed, setIsCollapsed]
  );

  const filterSidebarLinks = (userProfile) => {
    return navigationItems.filter((item) => {
      if (item.navigationExclusiveToRole.length === 0) {
        return true;
      }
      return item.navigationExclusiveToRole.some((role) =>
        userProfile?.role.includes(role)
      );
    });
  };
  const filteredLinks = filterSidebarLinks(userProfile);

  return (
    <div className="flex min-h-screen overflow-hidden">
      <div
        className={clsx(
          "fixed inset-y-0 z-40 flex flex-col transition-all duration-300",
          collapsed ? collapsedWidth : fullWidth,
          containerClassName
        )}>
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="flex h-16 shrink-0 items-center mb-0 pt-2">
            {collapsed ? heroCollapsedIcon : heroFullIcon}
          </div>
          <div className="flex items-center">
            <div className="border-t border-gray-200 w-full"></div>
            <button
              aria-expanded={!collapsed}
              onClick={toggleCollapse}
              className={clsx(
                "absolute top-18 -right-4 flex items-center border justify-center h-8 w-8 cursor-col-resize rounded-full bg-white hover:border-blue-500 p-1",
                collapseButtonClassName
              )}>
              {collapsed ? <ChevronRight /> : <ChevronLeft />}
            </button>
          </div>

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {filteredLinks.map((item) => (
                    <NavigationLink
                      key={`${item.label}-${item.to}`}
                      item={item}
                      isActive={item.isActive}
                      collapsed={collapsed}
                      isActiveClassName={isActiveClassName}
                      elementContainerClassName={elementContainerClassName}
                    />
                  ))}
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                {profileSection ? (
                  profileSection
                ) : (
                  <UserProfile
                    userProfile={userProfile}
                    collapsed={collapsed}
                    setIsCollapsed={setIsCollapsed}
                    handleLogout={handleLogout}
                    isActive={pathname.includes(profileRouteActiveString)}
                    isActiveClassName={isActiveClassName}
                    profileClassName={profileClassName}
                  />
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CustomSidebar);
