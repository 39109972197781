export const LABEL_EMAIL = "Email";
export const LABEL_PASSWORD = "Password";

// export const BUTTON_TEXT_REGISTER = "Register";
// export const BUTTON_TEXT_REGISTERING = "Registering...";

export const BUTTON_TEXT_SIGNUP = "Sign Up";
export const BUTTON_TEXT_SIGNING_UP = "Signing Up...";

export const BUTTON_TEXT_LOGIN = "Login";
export const BUTTON_TEXT_LOGGING_IN = "Logging in...";

export const BUTTON_TEXT_SENDING = "Sending...";
export const BUTTON_TEXT_SEND = "Reset password";

export const BUTTON_TEXT_RESETING = "Resetting...";
export const BUTTON_TEXT_RESET = "Reset the password";
