import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import networkService from "~/services/network.service";
import { PROFILE_INFO_API } from "~/shared/constants/api";
import useAuthStore from "~/store/auth.store";
import { SquareArrowOutUpRight } from "lucide-react";
import { handleError } from "../utils/errors.util";
import { ManageLicenseIcon } from "../icon/manage-license";
import { EducationAppIcon } from "../icon/education-app";
import { DownloadAppIcon } from "../icon/download-app";
import { RequestLicenseIcon } from "../icon/request-license";
import DownloadFromAppStore from "../icon/downloadFromAppStoreIcon";

const DashBoardCards = ({
  setOpenRequestModal,
}: {
  setOpenRequestModal: (val: boolean) => void;
}) => {
  const { user, accessToken } = useAuthStore((state) => ({
    user: state.user,
    accessToken: state.accessToken,
  }));
  const [cards, setCards] = useState([
    {
      id: 1,
      icon: <ManageLicenseIcon />,
      title: "Manage your license",
      desc: "In HeartFocus Portal, you can easily manage all your licenses and linked probes.",
      linkTo: "/licenses",
      linkButtonIcon: null,
      linkButtonContent: "Manage licenses",
    },
    {
      id: 2,
      icon: <EducationAppIcon />,
      title: "Master HeartFocus Education App",
      desc: "Dive into our comprehensive tutorial videos designed to help you maximize the potential of HeartFocus Education app. Explore the key features and best practices for success to unlock the full potential of our app!",
      linkTo: import.meta.env.VITE_APP_EDUCATION_APP_URL,
      linkButtonIcon: <SquareArrowOutUpRight size={18} />,
      linkButtonContent: "Watch video",
    },
    {
      id: 3,
      icon: <DownloadAppIcon />,
      title: "Get the app",
      desc: "Download our app directly from the App Store and get started!",
      linkTo: import.meta.env.VITE_APP_STORE_URL,
      linkButtonIcon: <DownloadFromAppStore size={50} />,
      linkButtonContent: "",
    },
    {
      id: 4,
      icon: <RequestLicenseIcon />,
      title: "Next an extra license?",
      desc: "Using multiple probes? Easily request an additional license for HeartFocus Education by contacting our support team.",
      linkTo: "",
      linkButtonIcon: null,
      linkButtonContent: "Request License",
    },
  ]);
  useEffect(() => {
    if (!user) return;
    const fetchUserInfoAndData = async () => {
      if (!user) return;
      try {
        // Fetch user info
        const userInfoResponse = await networkService.get<any>(
          `${PROFILE_INFO_API}/${user.userId}`
        );

        const userInfoData = userInfoResponse.data;
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    fetchUserInfoAndData();
  }, [user, accessToken]);

  return (
    <ul role="list" className="flex flex-col gap-3">
      {!user?.emailVerified && <li className="md:max-w-[1000px] font-space-grotesk">
        <div
          className="p-4 mb-3 rounded-lg bg-blue-50 dark:bg-blue-900 border border-blue-200 min-w-[240px] transition-all duration-300"
          role="alert">
          {/* <div className="flex items-center mb-3">
            <span className="bg-yellow-100 border border-yellow-500 text-yellow-800 text-sm font-semibold me-2 px-2.5 py-0.5 rounded">
              Information
            </span>
          </div> */}
          <p className="mb-2 text-sm text-blue-800 dark:text-blue-400">
            Please verify your email address to unlock all features.
            Check your inbox for the verification email.
          </p>
          <Link
            className="text-sm text-blue-800 underline font-medium hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
            to="/verify-email">
            Click to verify the email
          </Link>
        </div>
      </li>}
      {cards.map((card) => (
        <CardComponent
          key={card.id}
          id={card.id}
          title={card.title}
          icon={card.icon}
          linkTo={card.linkTo}
          desc={card.desc}
          openRequestLicenseModal={setOpenRequestModal}
          linkButtonContent={card.linkButtonContent}
          linkButtonIcon={card.linkButtonIcon}
        />
      ))}
    </ul>
  );
};

interface CardProps {
  id: number;
  title: string;
  icon: JSX.Element;
  desc: string | null;
  linkTo: string;
  openRequestLicenseModal?: (val: boolean) => void;
  linkButtonIcon?: JSX.Element;
  linkButtonContent: string;
}
const CardComponent: React.FC<CardProps> = ({
  id,
  title,
  icon,
  desc,
  openRequestLicenseModal,
  linkButtonContent,
  linkButtonIcon,
  linkTo,
}) => {
  const navigate = useNavigate();
  return (
    <li className="border rounded-lg px-6 py-7 md:min-h-[240px] flex md:flex-row flex-col md:gap-5 font-space-grotesk md:max-w-[1000px] hover:shadow-lg transition-shadow ease-in-out">
      <div className="flex items-center w-full justify-center md:w-auto">
        {icon}
      </div>
      <span className="w-full flex flex-col md:justify-between gap-4">
        <h1 className="font-bold text-xl">{title}</h1>
        {desc && (
          <p className="font-normal text-sm text-zinc-500 md:-mt-7">{desc}</p>
        )}
        <div>
          {id === 3 ? (
            <button
              onClick={() => {
                if (!linkTo) {
                  return;
                }
                if (
                  linkTo.startsWith("http://") ||
                  linkTo.startsWith("https://")
                ) {
                  window.open(linkTo, "_blank", "noopener,noreferrer");
                } else {
                  navigate(linkTo);
                }
              }}>
              {linkButtonIcon}
            </button>
          ) : (
            <button
              className="md:mt-3 text-center py-2 px-6 rounded-full bg-gray-950 text-white-a700 text-base flex items-center gap-3"
              onClick={() => {
                if (id === 4) {
                  openRequestLicenseModal && openRequestLicenseModal(true);
                }
                if (!linkTo) {
                  return;
                }
                if (
                  linkTo.startsWith("http://") ||
                  linkTo.startsWith("https://")
                ) {
                  window.open(linkTo, "_blank", "noopener,noreferrer");
                } else {
                  navigate(linkTo);
                }
              }}>
              {linkButtonIcon && linkButtonIcon}
              <span>{linkButtonContent}</span>
            </button>
          )}
        </div>
      </span>
    </li>
  );
};

export default DashBoardCards;
