import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { TableColumn } from "~/interfaces/interfaces";
import { IError } from "~/interfaces/shared.interface";
import { networkService } from "~/services";
import PageHeader from "~/shared/components/page-header.component";
import GenericTableWithSorting from "~/shared/components/table/table";
import Dropdown from "~/shared/components/ui/dropdown";
import NoProbe from "~/shared/components/ui/no-probe.component";
import { UserRole } from "~/shared/config";
import { CUSTOMER_DETAILS_API } from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import { getUniquePlanNames } from "~/shared/utils/helper.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";

export interface Customer {
  id: string;
  name: string;
  subscriptions: {
    plan: {
      name: string;
    };
  }[];
  owner: {
    id: string;
    lastName: string;
    firstName: string;
  };
}

export default function Home() {
  const [pageSize, setPageSize] = useState(7);
  const [pageNumber, setPageNumber] = useState(1);
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const [selectedOrg, setSelectedOrg] = useState({
    name: "All Organizations",
    id: null,
  });
  const [organizationOptions, setOrganizationOptions] = useState<
    { name: string; id: string | null }[]
  >([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const { user } = useAuthStore((state) => state);
  const [membersSearch, setMembersSearch] = useState("");
  const [totalProbeResults, setTotalProbeResults] = useState<{
    pageIndex: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  }>();
  const [sortDirection, setSortDirection] = useState<string>("");

  const adjustPageSize = () => {
    const offset = 120;
    const rowHeight = 100;
    const windowHeight = window.innerHeight;
    const numberOfRows = Math.floor((windowHeight - offset) / rowHeight);
    numberOfRows <= 3 ? setPageSize(5) : setPageSize(numberOfRows);
  };

  useEffect(() => {
    adjustPageSize();
    window.addEventListener("resize", adjustPageSize);
    return () => {
      window.removeEventListener("resize", adjustPageSize);
    };
  }, []);

  useEffect(() => {
    if (!user) return;
    const getOrganization = async () => {
      try {
        const response = await networkService.get<any>(CUSTOMER_DETAILS_API);
        setOrganizationOptions(() => [
          { name: "All Organizations", id: null },
          ...(response.data.results?.map((org: any) => ({
            name: org.name,
            id: org.id,
          })) ?? []),
        ]);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    user.role === UserRole.Admin && getOrganization();
  }, [user]);

  useEffect(() => {
    if (!user) return;
    const fetchCustomers = async () => {
      try {
        const response = await networkService.get<any>(
          `${CUSTOMER_DETAILS_API}?pageIndex=${pageNumber - 1}&pageSize=${pageSize}${selectedOrg.id === null ? "" : `&organizationId=${selectedOrg.id}`}${sortDirection ? `&orderBy=name:${sortDirection}` : ""}`
        );
        const customerData = response.data.results;
        setTotalProbeResults(response.data.meta);
        setCustomers(customerData);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchCustomers();
  }, [user, pageSize, pageNumber, selectedOrg, sortDirection]);

  const totalResults = totalProbeResults?.totalItems || 0;
  const pageCount = Math.ceil(totalResults / pageSize);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };


  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  const colDefs: TableColumn[] = [
    {
      field: "organizationName",
      type: "string",
      name: "Organization Name",
      isSortable: true,
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px]">
            <p className="text-sm">{item.name}</p>
          </div>
        );
      },
      onSort: () => toggleSortDirection()
    },
    {
      field: "owner",
      isSortable: false,
      type: "string",
      name: "Owner Name",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px]">
            <p className="text-sm">{item.owner}</p>
          </div>
        );
      },
      comparator: (
        a: { owner: string },
        b: { owner: string },
        direction: "ascending" | "descending"
      ) => {
        return direction === "ascending"
          ? a.owner.toLowerCase().localeCompare(b.owner.toLowerCase())
          : b.owner.toLowerCase().localeCompare(a.owner.toLowerCase());
      },
    },
    {
      field: "plan",
      isSortable: false,
      type: "string",
      name: "Plan List",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px]">
            <p className="text-sm text-center">
              {item.plan.length > 0
                ? getUniquePlanNames(item.plan).join(", ")
                : "N/A"}
            </p>
          </div>
        );
      },
    },
    {
      field: "actions",
      isSortable: false,
      type: "string",
      name: "Actions",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px] items-center flex justify-center">
            <div className="flex items-center space-x-2">
              <Link
                to={`/customers/${item.id}`}
                className="text-indigo-600 hover:text-indigo-900">
                View details
              </Link>
              <Link to={`/customers/update-customers/${item.id}`}>
                <img
                  src="/edit.svg"
                  alt="edit"
                  className="ml-3 h-6 w-6 hover:bg-zinc-100 rounded-full"
                  width={20}
                  height={20}
                />
              </Link>
            </div>
          </div>
        );
      },
    },
  ];

  const rowDefs: Record<string, any>[] = customers.map((item) => ({
    organizationName: {
      name: item.name,
    },
    owner: {
      owner: item.owner.firstName,
    },
    plan: {
      plan: item.subscriptions,
    },
    actions: {
      id: item.id,
    },
  }));

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Customers" />

      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center">
          <img
            src="/filter-icon.svg"
            alt="Filter Icon"
            width={20}
            height={20}
            className="mr-2"
          />
          <span className="text-neutral-900 font-medium font-space-grotesk">
            Filter by:
          </span>
          {/* Organization Filter */}
          <div className="px-2">
            <Dropdown
              options={organizationOptions}
              onChange={setSelectedOrg}
              renderOption={(option) => option.name}
              selectedOption={selectedOrg}
            />
          </div>
        </div>
      </div>
      <div className="mt-8">
        {customers.length === 0 ? (
          <NoProbe />
        ) : (
          <GenericTableWithSorting
            column={colDefs}
            row={rowDefs}
            searchField="organizationName.name"
            searchTerm={membersSearch}
            showPagination={true}
            totalResults={totalResults}
            pageSize={pageSize}
            pageCount={pageCount}
            resetToFirstPage={false}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}
