import React, { useEffect, useState } from "react";

interface CarouselProps {
  images: string[];
  interval?: number;
}

const Carousel: React.FC<CarouselProps> = ({ images, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const switchImage = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const intervalId = setInterval(switchImage, interval);

    return () => clearInterval(intervalId);
  }, [images.length, interval]);

  return (
    <div className="relative w-full h-0 pb-[70%] max-w-full overflow-hidden rounded-lg">
      {images.map((src, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-all duration-1000 ease-in-out flex justify-center items-center ${index === currentIndex ? "opacity-100 z-10" : "opacity-0 z-0"
            }`}
          style={{ transitionDuration: `${interval / 3}ms` }}
        >
          <img
            src={src}
            alt={`Slide ${index}`}
            className="w-full h-full object-contain"
          />
        </div>
      ))}
    </div>
  );
};

export default Carousel;
