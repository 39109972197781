import { ArrowLeft, ChevronLeft } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const PageHeader = ({
  title,
  showBack = false,
}: {
  title: string;
  showBack?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="flex gap-4 items-center mb-4">
        {showBack && (
          <button onClick={() => navigate(-1)} className="border-2 border-black-600 p-2 rounded-full">
            <ChevronLeft size={24} />
          </button>
        )}
        <h1 className="text-2xl font-bold font-space-grotesk flex-1 text-center pr-14 md:text-left">{title}</h1>
      </div>
      <div className="border-t border-[var(--Stroke,#EFEFF4)]"></div>
    </React.Fragment>
  );
};

export default PageHeader;
