import React from "react";
import { Minimize } from "lucide-react";
import Carousel from "./carousel";

interface FullScreenModalProps {
  images: string[];
  isOpen: boolean;
  onClose: () => void;
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({ images, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
      <div className="relative w-full max-w-screen-lg bg-black-900 h-full">
        {images.length === 0 ? (
          <div className="h-full flex items-center justify-center">
            <img
              src="/defaultImage.jpeg"
              alt="defaultImage"
              className="w-[290px] h-full object-contain max-h-[350px] object-left-top"
            />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center">
            <Carousel images={images} interval={6000} />
          </div>
        )}

        {/* Close Button */}
        <button
          className="absolute z-50 bottom-14 right-5 bg-[#C4C6EE] rounded-full p-2 shadow-md"
          onClick={onClose}
          type="button"
        >
          <Minimize />
        </button>
      </div>
    </div>
  );
};

export default FullScreenModal;
