import { ErrorMessage, Field, Form, Formik } from "formik";
import { AlertCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import { CardDescription } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { UserRole } from "~/shared/config";
import { FORGOT_PASSWORD_API } from "~/shared/constants/api";
import { FORGOT_PASSWORD_ERROR } from "~/shared/constants/errormessage";
import {
  BUTTON_TEXT_SEND,
  BUTTON_TEXT_SENDING,
} from "~/shared/constants/label";
import { FORGOT_PASSWORD_SUCCESS } from "~/shared/constants/successmessage";
import HeartFocusIcon from "~/shared/icon/heartfocus.icon";
import TabletIcon from "~/shared/icon/tablet.icon";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";

const ForgotPassword = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (!user) return;

    if (user?.role === UserRole.Admin) {
      navigate("/home");
    } else {
      if (user?.organizationId) {
        navigate("/dashboard");
      } else {
        navigate("/onboarding/organization");
      }
    }
  }, [user, navigate]);

  const handleForgotPassword = async (email: string) => {
    setLoading(true);
    try {
      const response = await networkService.post<any>(FORGOT_PASSWORD_API, {
        email,
      });
      if (response) {
        toast.success(FORGOT_PASSWORD_SUCCESS);
        setShowSuccess(true);
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
      setError(FORGOT_PASSWORD_ERROR);
    } finally {
      setLoading(false);
    }
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Oops! This email format looks incorrect.")
      .required("This field is required"),
  });
  return (
    <div className="flex min-h-screen font-space-grotesk">
      <div className="flex md:w-2/5 w-full flex-col justify-between items-center px-6 lg:px-8 z-10 py-10">
        <div className="font-sans text-2xl font-bold leading-tight text-center bg-radial-gradient bg-clip-text">
          <HeartFocusIcon />
        </div>
        {/* form */}

        <div className="w-full xl:w-4/5 flex flex-col mt-auto">
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleForgotPassword(values.email);
            }}>
            {({ handleChange, values, errors, touched }) =>
              showSuccess ? (
                <div>
                  <CardDescription className="text-black flex flex-col gap-2 font-space-grotesk">
                    <span className="text-4xl font-bold">Check your email</span>
                    <br />
                    <span className="block text-base font-normal leading-5">
                      {`We have sent a link to ${values.email} to reset your
                      password.`}
                    </span>
                    <span className="block text-base font-normal leading-5">
                      Please check your inbox and follow the instructions to
                      reset your password. If you don’t see it, check your spam
                      or junk folder.
                    </span>
                  </CardDescription>

                  <div className="flex flex-col gap-5 mt-8">
                    <hr className="w-full border-t border-gray-300" />
                    <Link
                      to="/login"
                      className="text-[#000] font-space-grotesk text-base font-semibold leading-[24px] underline">
                      Login
                    </Link>
                  </div>
                </div>
              ) : (
                <Form className="flex flex-col gap-12">
                  {/* header */}
                  <CardDescription className="text-black flex flex-col gap-2">
                    <span className="text-4xl font-bold">
                      Reset your password?
                    </span>
                    <span className="block text-base font-normal">
                      Forgot your password? If this email address is associated with an account, a reset link will be sent to you.
                    </span>
                  </CardDescription>
                  {error && (
                    <p className="text-[#F87171] text-sm font-space-grotesk font-medium flex items-center gap-3 border border-[#F87171] px-4 py-3 rounded-lg bg-[#FEF2F2] -my-4">
                      <AlertCircle size={16} />
                      <span>{error}</span>
                    </p>
                  )}
                  <div className="flex flex-col gap-6">
                    <div className="flex w-full flex-col gap-1 items-start">
                      <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-semibold leading-normal mb-1">
                        Email
                      </Label>
                      <div className="w-full">
                        <Field
                          name="email"
                          type="email"
                          className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.email && touched.email ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                          placeholder="Enter your email address"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          children={(errorMessage) => (
                            <div className="text-[#F87171] text-xs mt-2 flex gap-1 items-center">
                              <AlertCircle size={14} />
                              <span>{errorMessage}</span>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="flex w-full justify-center items-center bg-angular-gradient rounded-full p-0.5 mt-5">
                      <button
                        type="submit"
                        className="w-full font-semibold bg-white-a700 rounded-full p-1.5 hover:bg-angular-gradient"
                        disabled={loading}>
                        {loading ? BUTTON_TEXT_SENDING : BUTTON_TEXT_SEND}
                      </button>
                    </div>

                    <div className="flex flex-col gap-5 mt-5">
                      <hr className="w-full border-t border-gray-300" />
                      <div className="w-full justify-center">
                        <Link
                          to="/login"
                          className="text-[#000] font-space-grotesk text-[16px] md:text-sm font-normal leading-[24px]">
                          Remembered your password?{" "}
                          <span className="font-semibold underline">Login</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>

        {/* terms and conditions */}
        <div className="w-full xl:w-4/5 flex flex-col mt-auto 2xl:items-center">
          <p className="text-xs text-gray-700 text-center">
            By continuing, you agree to Heartfocus's{" "}
            <span className="inline-block">
              <a
                href="https://www.heartfocus.ai/portal-terms-conditions"
                className="text-gray-700 hover:underline" target="_blank">
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.heartfocus.ai/education-privacy-policy"
                className="text-gray-700 hover:underline" target="_blank">
                Privacy Policy
              </a>
              .
            </span>
          </p>
        </div>
      </div>
      {/* image */}
      <div className="w-3/5 h-screen hidden md:block relative overflow-hidden">
        <img
          src="/Gradientcropped.png"
          alt="Background Image"
          className="w-full h-full"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center z-10 md:py-12 2xl:py-0">
          <div className="text-4xl font-bold mb-2 pt-10">
            We believe No Heart Can Wait&trade;
            {/* <div
              style={{
                background: "black",
                display: "inline-block",
                padding: "0.15rem 0.5rem",
                marginLeft: "0.5rem",
              }}>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#e3e1f5] via-[#d4d3f0] to-[#b3b1e7]">
                No Hearts Can Wait.
              </span>
            </div> */}
          </div>
          <p className="text-lg mb-6">
            Join us in bringing heart health to the whole world.
          </p>
          <TabletIcon />
          <p className="pb-5">
            Learn more about HeartFocus{" "}
            <a href="https://heartfocus.ai" className="underline" target="_blank">
              here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
